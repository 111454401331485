import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FilterService } from '../../../service/filter.service';
import { DataCache } from '../../../interface/data-cache';
import { MatTableDataSource } from '@angular/material/table';
import { DataCacheService } from '../../../service/data-cache.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements DataCache, OnInit, OnDestroy {

  /**
    * Avoid recreate MatTableDataSource with contructor when use SearchComponent
    *
    * set `MatTableDataSource.data` instead
    *
    do:
    ```
        setTable(newData: any[]){
              this.table.data = newData;
        }
    ```

    dont:
    ```
        setTable(newData: any[]){
              this.table = new MatTableDataSource(newData);
        }
    ```
     */
  @Input('data') data: MatTableDataSource<any>;
  @Input('component') component: string;

  @ViewChild('userInput', { static: true }) userInput: ElementRef<HTMLInputElement>;

  constructor(
    public filterService: FilterService,
    private _dataCacheService: DataCacheService
  ) { }

  compareByProperty(f1: FilterService['selectedFilter'], f2: FilterService['selectedFilter']) {
    return f1 && f2 && f1.property == f2.property;
  }

  ngOnInit(): void {
    this.filterService.defineFilter(this.component, this.data);
    this.getCache();
  }

  ngOnDestroy(): void {
    this.setCache();
    this.filterService.clearFilters()
  }

  getCache(isAsync?: boolean): void {
    const config = this._dataCacheService.getConfig('SEARCH_' + this.component);
    if (config && config.filter) {
      const searchValue = config.filter.userInput;
      this.filterService.userInput = searchValue;
      this.filterService.filters = config.filter.filters;
      this.filterService.activeFilters = config.filter.activeFilters;
      if (config.filter.selectedFilter && searchValue) {
        const selectedFilter = this.filterService.filters.find(f => f.property === config.filter.selectedFilter.property)
        selectedFilter.searchValue = searchValue;
        this.filterService.selectedFilter = selectedFilter;
        this.userInput.nativeElement.value = searchValue;
      }

      // apply filter again
      this.data.data = this.data.data
    }
  }

  setCache(): void {
    const filters = JSON.parse(JSON.stringify(this.filterService.filters));
    this._dataCacheService.setConfig('SEARCH_' + this.component, {
      filter: {
        userInput: this.filterService.userInput,
        filters: filters,
        activeFilters: this.filterService.activeFilters,
        selectedFilter: this.filterService.selectedFilter
      }
    });
  }

  focusIntoInput() {
    setTimeout(() => {
      this.userInput.nativeElement.focus()
    }, 0);

  }

  clearInput(){
    this.filterService.clearInput();
  }

}
