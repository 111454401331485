import { Injectable } from '@angular/core';
import { DataCacheConfig } from '../interface/data-cache-config';

@Injectable({
  providedIn: 'root'
})
export class DataCacheService {

  private _configs: Map<string, DataCacheConfig>;

  constructor() {
    this._configs = new Map<string, DataCacheConfig>()
  }

  getConfig(component: string): DataCacheConfig {
    return this._configs.get(component);
  }

  setConfig(component: string, config: DataCacheConfig) {
    this._configs.set(component, config);
  }

  reset(): void {
    this._configs.clear();
  }

}
