import { Injectable } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Utilities } from '../class/utilities';
import { PaymentMethodPipe } from '../shared/pipes/payment-method.pipe';

const FILTERS = {
  banks: ['code', 'name', 'description'],
  budget: ['clientObject.name', 'vehicleObject.plate', 'vehicleObject.brand', 'vehicleObject.model', 'budgetCode'],
  all: ['clientObject.name', 'vehicleObject.plate', 'vehicleObject.brand', 'vehicleObject.model', 'budgetCode', 'codeSystem'],
  rejected: ['clientObject.name', 'vehicleObject.plate', 'vehicleObject.brand', 'vehicleObject.model', 'budgetCode', 'codeSystem'],
  ro: ['clientObject.name', 'vehicleObject.plate', 'vehicleObject.brand', 'vehicleObject.model', 'codeSystem'],
  opened: ['clientObject.name', 'vehicleObject.plate', 'vehicleObject.brand', 'vehicleObject.model', 'codeSystem'],
  integrated_opened: ['clientObject.name', 'vehicleObject.plate', 'vehicleObject.brand', 'vehicleObject.model', 'codeSystem'],
  closed: ['clientObject.name', 'vehicleObject.plate', 'vehicleObject.brand', 'vehicleObject.model', 'codeSystem'],
  ended: ['clientObject.name', 'vehicleObject.plate', 'vehicleObject.brand', 'vehicleObject.model', 'codeSystem'],
  legacyOS: ['observacao', 'numeroOS', 'cliente.nome', 'cliente.telefone', 'cliente.documento', 'placa'],
  clients: ['name', 'document', 'phone1'],
  invoices: ['nNF', 'serie', 'emitName', 'osNumber', 'orderNumber'],
  labors: ['code', 'description'],
  parts: ['code', 'description', 'fabricCode', 'EANcode'],
  order: ['code', 'name'],
  receipt: ['clientObject.name', 'ro.code'],
  roTypes: ['code', 'type'],
  suppliers: ['name', 'document', 'phone1'],
  titles: ['invoiceOrOSNumber', 'supplier_client.name'],
  users: ['name', 'email'],
  vehicles: ['clientObject.name', 'model', 'brand', 'year', 'plate'],
  machines: ['clientObject.name', 'model', 'brand', 'year', 'plate'],
  titleTypes: ['description'],
  expenseTypes: ['code', 'description'],
  brands: ['description'],
  applications: ['description'],
  groups: ['description'],
  subgroups: ['description'],
  laborTypes: ['description'],
  paymentConditions: ['description', 'condition', 'form', 'parcels'],
  operations: ['description'],
  cashierEvents: ['description', 'expenseType.description'],
  customStates: ['description'],
  cartes: ['model', 'series']
}

interface Filter {
  property: string,
  searchValue: string
}

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  datasource = new MatTableDataSource<any>([]);
  filters: Filter[];
  activeFilters: number;
  private _selectedFilter: Filter;

  constructor(
    private paymentMethodPipe: PaymentMethodPipe,
    private translate: TranslateService,
  ) {
  }

  public get selectedFilter(): Filter {
    return this._selectedFilter;
  }

  public set selectedFilter(value: Filter) {
    this._selectedFilter = value;
    this.filters.forEach(filter => {
      if (value) {
        if (value.searchValue) {
          if (filter.property == value.property) {
            this.userInput = filter.searchValue
          }
        } else {
          this.userInput = "";
        }
      }
    })
  }

  public get userInput(): string {
    return this.datasource.filter;
  }

  public set userInput(value: string) {
    if (this.selectedFilter) {
      this.filters.forEach(filter => {
        if (filter.property === this.selectedFilter.property) {
          filter.searchValue = value
          this.updateActiveFilters();
        }
      })
    }
    if (value) {
      this.datasource.filter = value
    } else {
      /**
       * filter predicate not detect empty strings
       *
       * see [this issue](https://github.com/angular/components/issues/9967)
       */
      this.datasource.filter = new String('') as any;
    }
  }


  /**
   * Define the params to filter a MatTableDataSource according screen
   *
   * @param component The current layout component
   * @param dataSource The MatTableDataSource with data
   */
  defineFilter(component: string, dataSource: MatTableDataSource<any>) {
    this.filters = [];
    this.activeFilters = 0;
    this.datasource = dataSource;
    FILTERS[component].forEach(property => {
      this.filters.push({ property: property, searchValue: "" })
    });

    this.datasource.filterPredicate = (element: any, search: String) => {
      search = Utilities.removeDiacritics(search.toString().toLowerCase());
      //val CPF and CNPJ
      /*const documentRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$|^\d{3}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;
      if (documentRegex.test(search.toString())) {
        search = Utilities.removeCharacters(search.toString().toLowerCase());
      }*/
      if (component == 'clients' || component == 'suppliers') {
        search = Utilities.removeCharacters(search.toString().toLowerCase());
      }
      this.updateActiveFilters()
      let include = false;
      if (!this.activeFilters) {
        for (const filter of this.filters) {
          const value = this.getValue(element, filter.property);
          if (!!value && value.includes(search)) {
            include = true;
          }
        }
      } else {
        include = true
        for (const filter of this.filters) {
          if (filter.searchValue) {
            let value = this.getValue(element, filter.property)
            if (!value || (value && !value.includes(search))) {
              include = false
            }
          }
        };
      }
      return include;
    };
  }

  clearInput() {
    this.datasource.filter = "";
  }

  // set '' the filter property of selected chip filter
  removeFilter(property: string) {
    if (this.selectedFilter && this.selectedFilter.property === property) {
      this.selectedFilter = undefined;
    }
    this.filters.forEach(f => {
      if (f.property === property) {
        if (f.searchValue === this.userInput) {
          this.userInput = ""
        }
        f.searchValue = "";
      }
    })

    this.updateActiveFilters();
    if (!this.activeFilters) {
      this.clearInput()
    }

    this.userInput = this.userInput
  }

  clearFilters() {
    this.selectedFilter = undefined;
    this.filters.forEach(filter => filter.searchValue = "");
    this.updateActiveFilters()
    this.clearInput()
  }

  /**
   * Count actitive filters
   *
   */
  private updateActiveFilters() {
    let count = 0;
    this.filters.forEach(filter => {
      if (filter.searchValue) {
        count++;
      }
    });
    this.activeFilters = count;
  }

  private getValue(element: any, field) {
    const idx = field.split('.');
    if (!idx[0]) {
      return ""
    }
    if (idx[0] === "parcels" && element["parcels"]) {
      return element["parcels"].length.toString();
    }
    let value = (idx.length == 2 && element[idx[0]] ? element[idx[0]][idx[1]] : element[idx[0]]);
    if (idx[0] === 'form') {
      const form = this.translate.instant(('ELEMENT.PAYMENT_METHOD.' + this.paymentMethodPipe.transform(value)))
      return Utilities.removeDiacritics(form).toLowerCase()
    }
    if (idx[0] === 'phone') {
      return Utilities.removeMask(value);
    }
    return value ? Utilities.removeDiacritics(value.toString()).toLowerCase() : "";
  }

}
