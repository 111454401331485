<div [ngClass]="{
    'keepOpened': !!userInput.length, 
    'searchContainer':true, 
    'mat-form-field':true,
    'mobile-input': isMobile
    }">

    <input type="text"
        [(ngModel)]="userInput"
        (input)="onSearch()"
        placeholder="Buscar"
        #input>
    <div class="btnSearch"
        (click)="clearInput(input)">
        <i class="material-icons">{{ userInput.length ? 'clear' : 'search' }}</i>
    </div>

</div>