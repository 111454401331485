<mat-form-field class="search-field"
                appearance="outline">
  <mat-select placeholder="Adicionar filtro"
              #filterSelect
              [compareWith]="compareByProperty"
              (selectionChange)="focusIntoInput()"
              [(ngModel)]="filterService.selectedFilter"
              name="item">
    <mat-option (click)="filterService.clearFilters()">{{ 'FILTER.none' | translate }}</mat-option>
    <mat-optgroup label="Filtros">
      <mat-option *ngFor="let filter of filterService.filters"
                  [value]="filter"> {{ 'FILTER.'+ filter.property | translate }}
      </mat-option>
    </mat-optgroup>
  </mat-select>
</mat-form-field>
<mat-form-field class="search-field align"
                appearance="outline">
  <mat-label class="padding">{{ 'SEARCH.' + component | uppercase | translate }}</mat-label>
  <input matInput
         #userInput
         [(ngModel)]="filterService.userInput" />
  <mat-icon matPrefix>search</mat-icon>
  <button aria-label="clear"
          *ngIf="filterService.userInput"
          class="clear-button"
          mat-icon-button
          matSuffix
          (click)="filterService.clearInput()">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
<mat-chip-set #chipGrid>
  <ng-container *ngFor="let filter of filterService.filters">
    <mat-chip *ngIf="filter.searchValue"
              color="primary"
              selected>
      {{ 'FILTER.'+filter.property | translate }}: "{{ filter.searchValue }}"
      <mat-icon (click)="filterService.removeFilter(filter.property)"
                trailingIcon
                class="pointer">cancel</mat-icon>
    </mat-chip>
  </ng-container>
  <mat-chip color="warn"
            class="pointer"
            *ngIf="filterService.activeFilters > 1"
            (click)="filterService.clearFilters()"
            selected>Remover filtros</mat-chip>
</mat-chip-set>
